import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import { onClient } from '../../context'

const isSafari = () => {
  return onClient() && !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/)
}

const AttachedBackground = ({ url, children, top, className, attached }) => {
  return (
    <div
      style={{ backgroundImage: url && `url(${url})`, backgroundSize: 'cover'}}
      className={`${!isSafari() && 'attached-background'} ${top &&
        'position-top'} ${attached && 'super-attached'} ${className &&
        className}`}
    >
      {children}
    </div>
  )
}

export default AttachedBackground
